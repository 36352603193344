// mixin for product listings

import { mapGetters, mapMutations, mapActions } from 'vuex'
import image from './image'
export default {
  mixins: [image],
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedSize: null,
      showProductDetails: false
    }
  },
  computed: {
    ...mapGetters(['currentStore', 'currentItem', 'bases']),
    productSizes () {
      if (this.product && this.product.sizes && Array.isArray(this.product.sizes)) {
        return this.product.sizes.map((size) => {
          return {
            value: size,
            text: (this.product.sizes.length > 1 ? (size.size + ' £' + size.price) : ('£' + size.price))
          }
        })
      }
      return []
    }
  },
  watch: {
    productSizes (sizes) {
      if (sizes.length) {
        this.selectedSize = sizes[0].value
      }
    }
  },
  mounted () {
    if (this.productSizes.length) {
      this.selectedSize = this.productSizes[0].value
    }
  },
  methods: {
    ...mapMutations(['clearCurrentItem']),
    ...mapActions(['addToCart', 'setCurrentItem']),
    async selectProduct (product, addToCart = false, e = false) {
      product = JSON.parse(JSON.stringify(product))
      const selectedProduct = Object.assign(product, {
        products: false,
        cartIndex: null,
        comment: '',
        allergies: []
      })
      if (['web', 'pos'].includes(this.$platform)) {
        this.$set(selectedProduct, 'qty', 1)
      } else {
        selectedProduct.qty = 1
      }
      selectedProduct.selectedSize = this.selectedSize
      if (this.$platform === 'pos') {
        if (selectedProduct.hasOwnProperty('selectedSize') && (selectedProduct.product_type === 'pizza' || selectedProduct.product_type === 'deal' || selectedProduct.items.length || selectedProduct.sizes.length > 1)) {
          if (selectedProduct.product_type !== 'side') {
            delete selectedProduct.selectedSize
          }
        }
      }
      if (addToCart) {
        if (['pizza', 'halfNHalf'].includes(selectedProduct.product_type)) {
          if (selectedProduct.default_base) {
            const base = this.bases.filter(base => base.id === parseInt(selectedProduct.default_base))[0]
            if (base) {
              this.$set(selectedProduct, 'selectedBase', base)
            } else {
              this.$set(selectedProduct, 'selectedBase', this.bases[0])
            }
          } else {
            this.$set(selectedProduct, 'selectedBase', this.bases[0])
          }
        }
        selectedProduct.price = parseFloat(selectedProduct.selectedSize.price)
        selectedProduct.extraPrice = 0
        const idx = await this.addToCart(selectedProduct)
      }
      
      if (['web', 'app'].includes(this.$platform) && ((product.product_type === 'side' && product.items.length) || addToCart)) {
        const app = document.querySelector('#app')
        if (app) {
          const event = new CustomEvent('productAddedToCart', { detail: { product: JSON.parse(JSON.stringify(selectedProduct)) } })
          app.dispatchEvent(event);
        }
        return this.$root.$emit('productAddedToCart', { product: JSON.parse(JSON.stringify(selectedProduct)) })
      }

      this.clearCurrentItem()
      this.setCurrentItem({ product: selectedProduct }) // set the current item data for further customizations

      if (product.items.length === 0 && e && typeof this.triggerFlyingCircle === 'function') {
        this.triggerFlyingCircle(e.target)
      }

      switch (product.product_type) {
        case 'deal':
          this.redirectToDeal(product)
          break
        case 'pizza':
          this.redirectToProduct(product)
          break
        default:
          if (this.$platform === 'pos') {
            if (selectedProduct.product_type === 'pizza' || selectedProduct.product_type === 'deal' || selectedProduct.items.length || selectedProduct.sizes.length > 1) {
              this.redirectToProduct(product)
              if (selectedProduct.product_type === 'side') {  // When it's side, we add it first to cart and then also allow customization
                const cartIndex = await this.addToCart(selectedProduct)
                this.setCurrentItem(Object.assign(this.currentItem, { cartIndex }))
              }
            }
          } else {
            product.items.length ? this.redirectToProduct(product) : this.addToCart(selectedProduct)
          }
          break
      }
    }
  }
}