//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'
import StoreDetail from '@/components/StoreDetail'
import { checkDealSchedule } from '@/../common/utils/dealSchedule.js'

export default {
  name: 'HomePage',
  components: {
    StoreDetail
  },
  head () {
    return {
      title: this.$getPageTitle('Home')
    }
  },
  computed: {
    ...mapGetters(['products', 'currentStore', 'storeInfo']),
    productList () {
      if (this.products.deal) {
        return this.products.deal.items.filter(item => checkDealSchedule(item, this.storeInfo ? this.storeInfo.schedule : null))
      }
      return []
    }
  }
}
