import logoAlt from '../../assets/logo-alt.png'
export default {
  methods: {
    getImage (product, position = 'default') {
      if (product && product.assets && Array.isArray(product.assets)) {
        const asset = product.assets.find(asset => asset.position === position)
        return asset ? asset.path : (logoAlt ? logoAlt : false)
      }
      return logoAlt
    },
  },
  computed: {
    activeProductImg () {
      if (this.activeProduct) {
        return this.getImage(this.activeProduct)
      }
    }
  }
}
