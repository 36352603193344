import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e08c2b04"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Product: require('/home/uattopspizzaco/builds/fZHTZxRpf/0/standbyteam/topspizza.co.uk/frontend/web/components/Product.vue').default,StoreDetail: require('/home/uattopspizzaco/builds/fZHTZxRpf/0/standbyteam/topspizza.co.uk/frontend/web/components/StoreDetail.vue').default})
