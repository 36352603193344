//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import productListingItem from '../../common/mixins/products/productListingItem'
import redirectWeb from '../../common/mixins/redirectWeb'

export default {
  name: 'Product',
  mixins: [productListingItem, redirectWeb]
}
